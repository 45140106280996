<template>
  <div class="index-container">
    <div>
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(img, idx) in imageList" :key="idx">
          <div class="flex justify-center">
            <van-image :src="img"></van-image>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- <div class="zb">
      <van-image :src="zbImg"></van-image>
      <van-button class="go-zb" round type="danger" @click="goZb"
        >进入直播间</van-button
      >
      <div class="zb-descp">
        <span class="van-multi-ellipsis--l2"
          >直播间免费接新区账号打造，花最少得钱，装最大的逼~</span
        >
      </div>
    </div> -->
    <div class="btn-group flex justify-between flex-wrap">
      <div
        class="btn-item"
        v-for="(btn, index) in btnList"
        :key="index"
        @click="goNext(btn)"
      >
        <div class="btn-item-content">{{ btn.name }}</div>
      </div>
    </div>
    <div class="join flex flex-column align-center">
      <span style="color: #999;font-size: 12px;">长按识别加入群聊</span>
      <van-image :src="agentSetting.groupQrUrl" class="qr-image"></van-image>
      <span>让我们一起并肩作战!</span>
    </div>
    <div class="others">
      <div class="nav-title flex justify-between align-center">
        <van-image :src="Icon1" class="icon-img"></van-image>
        <span>福利介绍</span>
        <van-image :src="Icon1" class="icon-img"></van-image>
      </div>
      <div class="game-desc">
        <p class="desc-title">
          <span>[新天隆16职业国际版，充值比例1:1000]</span>
        </p>
        <p class="desc-content">
          <span
            >福利翻倍，新手注册就送福利翻倍，新手注册就送5神兽，百万绑元，1亿经验丹，扫码进群下载！</span
          >
        </p>
        <p class="desc-title">
          <span>[新增门派，道具，开启跨服]</span>
        </p>
        <p class="desc-content">
          <span
            >新增：点苍，华山，桃花，重楼护腕，授印，神兽鲲，活动同步官方</span
          >
        </p>
        <p class="desc-title">
          <span>[每日挑战boss获取海量绑元，呼朋唤友来霸服]</span>
        </p>
        <p class="desc-content">
          <span
            >游戏内充礼包全面更新，更有超级线下每日，每周总累计奖励等你拿，拿奖拿到手抽筋</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line
import { Swipe, SwipeItem, Image, Button } from "vant";
import Icon1 from "@/assets/images/icon_1.png";
import { mapMutations, mapState } from "vuex";
import zbImg from "@/assets/images/zb_img.png";

export default {
  name: "TlbbIndex",
  components: {
    // 'van-swipe': Swipe,
    // 'van-swipe-item': SwipeItem,
    "van-image": Image
    // "van-button": Button
  },
  mounted() {},
  data() {
    return {
      Icon1,
      zbImg,
      imageList: [zbImg],
      btnList: [
        {
          name: "注册账号",
          routeName: "TlbbRegister"
        },
        {
          name: "游戏下载",
          routeName: "TlbbDownload"
        },
        {
          name: "游戏充值",
          routeName: "TlbbPay"
        },
        {
          name: "奖励说明",
          routeName: "PayRewardDesc"
        },
        {
          name: "新手礼包",
          routeName: "NewReward"
        },
        {
          name: "新区攻略",
          routeName: "Gl"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      setting: state => state.setting.setting,
      agentSetting: state => state.setting.agentSetting
    })
  },
  methods: {
    ...mapMutations({
      changeZbQrStatus: "zb/SetZbStatus"
    }),
    goZb() {
      // this.changeZbQrStatus(true)
      window.location.href = this.setting.zbImageUrl;
    },
    goNext(btn) {
      if (btn.routeName === "TlbbDownload") {
        if (!this.setting.downloadBySelf) {
          if (this.setting.downloadUrl) {
            window.location.href = this.setting.downloadUrl;
          } else {
            this.$dialog({
              title: "提示",
              message: "请配置下载地址"
            });
          }
        } else {
          this.$router.push({ name: btn.routeName });
        }
      } else {
        this.$router.push({ name: btn.routeName });
      }
    }
  },
  watch: {
    "setting.popContent"(nVal) {
      if (nVal) {
        this.$dialog.alert({
          title: "提示",
          message: nVal,
          confirmButtonText: "我知道了"
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.index-container {
  padding: 0 20px;
}

.btn-group {
  margin-top: 10px;

  .btn-item {
    margin-bottom: 10px;

    color: #fff;
    box-sizing: border-box;
    flex-basis: 33%;
    text-align: center;
    padding-right: 8px;

    .btn-item-content {
      box-sizing: border-box;
      background-color: #f00;
      padding: 10px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.others {
  margin-top: 20px;
  padding-bottom: 20px;
  .icon-img {
    width: 50px;
    height: 33px;
  }

  .nav-title {
    font-size: 20px;
    font-weight: bold;
    color: #ff9822;
    padding: 10px 0;
    margin-bottom: 20px;
  }

  .desc-title {
    color: #f00;
    padding: 20px 0;
    font-weight: bold;
  }
}

.join {
  padding: 20px;
}

.qr-image {
  width: 100px;
  height: 100px;
  margin: 10px 0;
}

.zb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  .zb-descp {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    padding: 10px 10px 15px 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .go-zb {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
</style>
